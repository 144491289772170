.point span {
    display: block;
    margin-right: 20px;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 50%;
    padding-top: 4px;
    text-align: center;
    color: rgb(255, 198, 54);
}

.point {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

.point p {
    margin-bottom: 0px;
}

.our-offer-content {
    margin-top: 3rem;
}

.our-offer video {
    width: 100%;
}