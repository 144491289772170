.call-to-action button {
    border-radius: 0px;
    border-width: 5px;
    min-width: 120px;
    font-size: 1.2rem;
    margin-left: 10px;
    margin-right: 10px;
}

.modal-close-tab button {
    border-radius: 0px;
    border-width: 5px;
    min-width: 120px;
    font-size: 1.2rem;
    margin-left: 10px;
    margin-right: 10px;
}

.call-to-action .col-sm-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}

.call-to-action-form {
    display: flex;
    justify-content: center;
}

.call-to-action h2 {
    margin-bottom: 4rem;
}

.button-empty {
    background: none;
    border-color: grey;
    color: gray;
    --bs-btn-active-color: white;
    --bs-btn-active-bg: grey;
    --bs-btn-active-border-color: goldenrod;
}

.button-empty:hover {
    background: grey;
    border-color: grey;
    color: white;
}

.button-awarded {
    background: rgb(255, 198, 54);
    border-color: rgb(255, 198, 54);
    font-weight: bold;
    --bs-btn-active-color: white;
    --bs-btn-active-bg: goldenrod;
    --bs-btn-active-border-color: goldenrod;
}

.button-awarded:hover {
    background: goldenrod;
    border-color: goldenrod;
}

.button-awarded:active {
    background: goldenrod;
    border-color: goldenrod;
}

.modal p{
    font-size: 1.2rem;
}