.our-offer h2 {
     text-align: center;
}

.our-offer li {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}

.our-offer p {
    text-align: justify;
}

@media only screen and (max-width: 768px) {
    .our-offer-content .points{
        margin-top: 2rem;
    }
}

@media only screen and (min-width: 768px) {
    .our-offer p {
        text-align: start;
    }
}