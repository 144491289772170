.form h2 {
    text-align: center;
}

.form input:focus {
    -webkit-box-shadow: 0px 0px 14px -5px rgb(255, 198, 54);
    -moz-box-shadow: 0px 0px 14px -5px rgb(255, 198, 54);
    box-shadow: 0px 0px 14px -5px rgb(255, 198, 54);
    border-color: goldenrod;
}

.form textarea {
    min-height: 8rem;
}

.form textarea:focus {
    -webkit-box-shadow: 0px 0px 14px -5px rgb(255, 198, 54);
    -moz-box-shadow: 0px 0px 14px -5px rgb(255, 198, 54);
    box-shadow: 0px 0px 14px -5px rgb(255, 198, 54);
    border-color: goldenrod;
}

.form .submit {
    margin-top: 2rem;
}

.error-label {
    color: rgb(221, 5, 5);
}