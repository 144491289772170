h1 {
  margin-bottom: 4rem;
}


.awarded {
  color: rgb(255, 198, 54);
  font-size: 2rem;
  font-weight: bold;
}

.section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  color: white;
  background-image: linear-gradient(
      to top,
      rgba(65,65,65,0.8),
      rgba(51,51,51,0.8)
    ), 
    url('../public/header.jpg');
  background-size: cover;
}

.header ul {
  list-style-image: url('../public//minus.png');
}

.header ul li {
  margin-bottom: 0.5rem;
  vertical-align: middle;
}

.animated-white-text {
  color: white;
  animation: white-color-change 2s linear, move-down 1s linear;
}

.animated-awarded-text {
  animation: awarded-color-change 2s;
}

@keyframes awarded-color-change {
  0% {
    color: rgba(65,65,65,0.8);
  }
  50% {
    color: white;
  }
  100% {
    color: rgb(255, 198, 54);
  }
}

@keyframes white-color-change {
  0% {
    color: rgba(65,65,65,0.8);
  }
  100% {
    color: white;
  }
}

@keyframes move-down {
  0% {
    transform: translateY(-100px);
  }
  20% {
    transform: translateY(-72px);
  }
  40% {
    transform: translateY(-48px);
  }
  60% {
    transform: translateY(-28px);
  }
  80% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateY(0);
  }
}

/* CSS dla telefonów */
@media only screen and (min-width: 320px) {
  html { font-size: 12px !important;}
}

/* CSS dla małych tabletów */
@media only screen and (min-width: 480px) {
  html { font-size: 14px !important;}
}

/* CSS dla tabletów */
@media only screen and (min-width: 768px) {
  html { font-size: 16px !important;}
}

/* CSS dla komputerów */
@media only screen and (min-width: 992px) {
  html { font-size: 18px !important;}
}

